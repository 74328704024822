import React, { useEffect, useState } from 'react';
import axios from "axios";
import {Button, Table} from "react-bootstrap";
import AutohideToast from "../Components/AutohideToast";
import { PairAsset } from "./PairAsset";
import { copyToClipboard } from "../Helpers/Clipboard";

const ListTokens = ({tenantId}) => {
    const [showToast, setShowToast] = useState(false)
    const [tokens, setTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState({})
    const [isAssetModalOpen, setIsAssetModalOpen] = useState(false)

    const openPairingAssetModal = (token) => {
        console.log(token)
        setSelectedToken(token)
        setIsAssetModalOpen(true)
    }

    const closePairingAssetModal = (token) => {
        setSelectedToken(token)
        setIsAssetModalOpen(false)
    }


    const handleGetEncodedDeploymentToken = (token) => {
        axios.get(`/api/v1/tenants/${tenantId}/tokens/${token.id}/encoded`)
            .then((response) => {
                console.log("THE ENCODED TOKEN IS", response.data)
                copyToClipboard(response.data.value)

                setShowToast(true)
            })

            .catch(error => {
                console.error('Error getting pairing token', error);
                alert("Failed to get pairing token");
            });
    };

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/tokens`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {setTokens(data); console.log("THE TOKENS",data)})
            .catch(error => console.error('Error fetching tokens:', error));
    }, []);

    const handleDeleteToken = (token) => {
        axios.delete(`/api/v1/tenants/${tenantId}/tokens/${token.id}`)
            .then(() => {
                alert("Pairing token deleted");
            })
            .catch(error => {
                console.error('Error deleting pairing token', error);
                alert("Failed to delete pairing token");
            });
    };

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/tokens`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {setTokens(data);})
            .catch(error => console.error('Error fetching tokens:', error));
    }, []);

    return (
        <div className="container">
            <h1>List of tokens</h1>
            <Table striped bordered hover >
                <thead>
                <tr>
                    <th>token ID</th>
                    <th>License Type</th>
                    <th>OrganizationId</th>
                    <th>PolicyId</th>
                    <th>Storage Region</th>
                    <th>Copy</th>
                    <th>Pair</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {tokens.map(token => (
                    <tr key = {token.id}>
                        <td>{token.id}</td>
                        <td>{token.licenseType}</td>
                        <td>{token.organizationId}</td>
                        <td>{token.policyId}</td>
                        <td>{token.storageLocation}</td>

                        <td><Button variant = "info" size = "sm" style = {{margin: "1rem auto", color:"white"}}
                                    onClick = {() => handleGetEncodedDeploymentToken(token)}>Copy Encoded Pairing
                                                                                        Token</Button></td>
                        <td><Button variant = "success" size = "sm" style = {{margin: "1rem auto"}}
                                    onClick = {() => openPairingAssetModal(token)}>Pair agent</Button></td>
                        <td><Button size = "sm"
                                    style = {{margin: "1rem auto", backgroundColor: "#dee2e6", border: "none"}}
                                    onClick = {() => handleDeleteToken(token)}>🗑️</Button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {isAssetModalOpen && selectedToken && <PairAsset closeModal={closePairingAssetModal} selectedToken = {selectedToken} tenantId={tenantId} />}

            <AutohideToast show = {showToast} setShow = {setShowToast} message = "Copied to clipboard!"/>
        </div>
    );
};

export default ListTokens;
