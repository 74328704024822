import './App.css';
import { useEffect, useState } from "react";
import PolicyContainer from "./PolicyPage/PolicyContainer";
import LicenseContainer from './LicensePage/LicenseContainer';
import {Row, Col, Container} from "react-bootstrap";
import Sidebar from "./SideBar/SideBar";
import ListAssets from "./Assets/ListAssets";
import ListTokens from "./TokensPage/ListTokens";
import TenantContainer from './Tenants/TenantContainer';

function App() {
    const [selectedSection, setSelectedSection] = useState(window.location.hash.slice(1) || 'tenants');
    const [selectedTenantId, setSelectedTenantId] = useState(null);

    // Load tenant ID from sessionStorage when the app first loads
    useEffect(() => {
        const savedTenantId = sessionStorage.getItem('selectedTenantId'); // Retrieve from sessionStorage
        if (savedTenantId) {
            setSelectedTenantId(savedTenantId);
        }
    }, []);

    // Function to handle tenant selection
    const handleTenantSelect = (tenantId) => {
        setSelectedTenantId(tenantId);
        sessionStorage.setItem('selectedTenantId', tenantId); // Save tenantId to sessionStorage
        updatePage('assets'); // Navigate to another section
    };

    const updatePage = (pg) => {
        setSelectedSection(pg);
        window.location.hash = pg;
    };

    useEffect(() => {
        if (!selectedTenantId) {
            updatePage('tenants')
        }
    }, []);

    const renderContent = () => {
        if (sessionStorage.getItem('selectedTenantId') && selectedTenantId == null) {
            console.log("selected TenantId", sessionStorage.getItem('selectedTenantId'))
            setSelectedTenantId(sessionStorage.getItem('selectedTenantId'))
        }
        switch (selectedSection) {
            case 'tenants':
                return <TenantContainer onSelectTenant={handleTenantSelect}/>;
            case 'assets':
                return <ListAssets tenantId={selectedTenantId}/>;
            case 'policies':
                return <PolicyContainer tenantId={selectedTenantId}/>;
            case 'tokens':
                return <ListTokens tenantId={selectedTenantId}/>;
            case 'licenses':
                return <LicenseContainer tenantId={selectedTenantId}/>
            default:
                return <div>Select an option from the sidebar</div>;
        }
    };

    return (
        <div className="App">
            <Container fluid>
                <Row>
                    <Col xs={2} style={{ padding: 0 }}>
                        <Sidebar onSelect={updatePage} tenantId={selectedTenantId} />
                    </Col>
                    <Col xs={10}>
                        {renderContent()}
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;

