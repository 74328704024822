import { Button, Row, Col, Container, Modal } from 'react-bootstrap';
import ListPolicies from "./ListPolicies";
import { CreatePolicy } from "./CreatePolicy";
import { useState } from "react";

const PolicyContainer = ({ tenantId }) => {
    const [modalPolicyOpen, setModalPolicyOpen] = useState(false);

    return (
        <Container fluid>
            <Row>
                <ListPolicies tenantId={tenantId} /> {/* Display ListPolicies */}
            </Row>

            {/* Button to open the modal */}
            <Row>
                <Col>
                    <Button variant="primary" onClick={() => setModalPolicyOpen(true)}>
                        Create Policy
                    </Button>
                </Col>
            </Row>

            {/* Modal for creating a new policy */}
            <Modal show={modalPolicyOpen} onHide={() => setModalPolicyOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatePolicy tenantId={tenantId} /> {/* Pass tenantId to CreatePolicy */}
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default PolicyContainer;
