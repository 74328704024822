import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";
import Modal from "react-bootstrap/Modal";

export const UpdateLicensePool = ({closeModal, selectedLicense, tenantId}) => {
    const [licenseType, setLicenseType] = useState('');
    const [totalLicenses, setTotalLicenses] = useState(0);
    const [usedLicenses, setUsedLicenses] = useState(0);
    const [licenseExpiry, setLicenseExpiry] = useState("")
    // Set state when selectedLicense changes
    useEffect(() => {
        if (selectedLicense) {
            setLicenseType(selectedLicense.licenseType || ''); // Fallback to empty string
            setTotalLicenses(selectedLicense.totalLicenses || 0); // Fallback to 0
            setUsedLicenses(selectedLicense.usedLicenses || 0); // Fallback to 0
            setLicenseExpiry(selectedLicense.expiryTime)
        }
    }, [selectedLicense]);
    // Function to handle incrementing/decrementing total licenses
    const incrementLicenses = () => setTotalLicenses(prev => prev + 1);
    const decrementLicenses = () => setTotalLicenses(prev => Math.max(0, prev - 1));  // Make sure it doesn't go below 0
    const handleSaveLicensePool = () => {
        let license = {
            licenseType: licenseType,
            baseQuantity: totalLicenses,
            expiryTime: new Date(licenseExpiry).toISOString()
        }

        axios.patch(`/internalapi/v1/tenants/${tenantId}/licenses`, license)
            .then(() => {
                alert("License Pool update request sent");

            })
            .catch(error => {
                console.error('Error updating license pool', error);
                alert("Failed to update license pool");
            });
        closeModal({...selectedLicense})
    };

    return (
        <Container>
            <Modal show={true} onHide={() => closeModal(selectedLicense)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update License Pool</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <h4>License Type</h4>
                        <Form.Group as = {Row} className = "mb-3" controlId = "formLicenseType">
                            <Form.Label column sm = {2}>License Type</Form.Label>
                            <Col sm = {10}>
                                <Form.Control
                                    type = "text"
                                    value = {licenseType}
                                    disabled = {true}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <h4>Used Licenses</h4>
                        <Form.Group as = {Row} className = "mb-3" controlId = "formUsedLicenses">
                            <Form.Label column sm = {2}>Used Licenses</Form.Label>
                            <Col sm = {10}>
                                <Form.Control
                                    type = "number"
                                    value = {usedLicenses}
                                    disabled = {true}
                                />
                            </Col>
                        </Form.Group>
                        <h4>License Expiry</h4>
                        <Form.Group as = {Row} className = "mb-3" controlId = "formLicenseType">
                            <Form.Label column sm = {2}>License Expiry</Form.Label>
                            <Col sm = {10}>
                                <Form.Control
                                    type="datetime-local"
                                    value = {licenseExpiry}
                                    onChange = {(e) => {setLicenseExpiry(e.target.value)}}
                                >
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <h4>Total Licenses</h4>
                        <Form.Group as = {Row} className = "mb-3" controlId = "formTotalLicense">
                            <Form.Label column sm = {2}>Total Licenses</Form.Label>
                            <Col sm = {8}>
                                <Form.Control
                                    type = "number"
                                    value = {totalLicenses}
                                    onChange = {(e) => setTotalLicenses(Math.max(0, Number(e.target.value)))}
                                />
                            </Col>
                            <Col sm = {2}>
                                <Button variant = "outline-success" onClick = {incrementLicenses}>+</Button>
                                <Button variant = "outline-danger" onClick = {decrementLicenses}>-</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal(selectedLicense)}>
                        Close
                    </Button>
                    <Button variant = "primary" onClick = {handleSaveLicensePool}>Save License Pool</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};