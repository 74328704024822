import React, { useState } from 'react';
import { Badge, Button, Nav } from 'react-bootstrap';

const Sidebar = ({ onSelect, tenantId }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    const badgeStyle = {
        fontSize: '1rem',  // Adjust the size here
        padding: '0.5rem', // Add some padding for better readability
        textWrap: 'wrap',
    };

    console.log("incoming", tenantId)

    return (
        <div
            style={{
                display: 'flex',
                height: '100vh',
                position: 'fixed',
                top: '0',
                left: '0',
                transition: 'width 0.3s ease',
                width: isCollapsed ? '60px' : '200px',
                backgroundColor: '#343a40',
                color: '#ffffff',
                borderRight: '1px solid #dee2e6',
            }}
        >
            {/* Sidebar Nav */}
            <Nav className="flex-column" style={{ padding: '15px', width: '100%' }}>
                <h2
                    style={{
                        color: '#ffffff',
                        marginBottom: '20px',
                        fontSize: isCollapsed ? '1rem' : '1.5rem',
                        transition: 'font-size 0.3s ease',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {!isCollapsed && 'Menu'}
                </h2>

                <Button
                    variant="link"
                    style={buttonStyle(isCollapsed)}
                    onClick={() => onSelect('tenants')}
                >
                    {!isCollapsed && 'Tenants List'}
                </Button>

                <Button
                    variant="link"
                    style={buttonStyle(isCollapsed)}
                    onClick={() => onSelect('assets')}
                >
                    {!isCollapsed && tenantId && 'Assets List'}
                </Button>

                <Button
                    variant="link"
                    style={buttonStyle(isCollapsed)}
                    onClick={() => onSelect('policies')}
                >
                    {!isCollapsed && tenantId && 'Policy List'}
                </Button>

                <Button
                    variant="link"
                    style={buttonStyle(isCollapsed)}
                    onClick={() => onSelect('tokens')}
                >
                    {!isCollapsed && tenantId && 'Token List'}
                </Button>

                <Button
                    variant="link"
                    style={buttonStyle(isCollapsed)}
                    onClick={() => onSelect('licenses')}
                >
                    {!isCollapsed && tenantId && 'Licenses'}
                </Button>
                {!isCollapsed && <Badge bg="secondary" style={badgeStyle}>{tenantId}</Badge>}
            </Nav>

            {/* Toggle Button (Simple CSS Arrows for Collapse/Expand) */}
            <Button
                onClick={toggleSidebar}
                style={{
                    position: 'absolute',
                    top: '10%',
                    right: '-20px', // Adjust to position the button outside the sidebar
                    transform: 'translateY(-50%)',
                    backgroundColor: '#343a40',
                    border: 'none',
                    padding: '5px',
                    color: '#ffffff',
                    cursor: 'pointer',
                }}
            >
                <span style={{
                    display: 'inline-block',
                    transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                }}>
                    &#9664;
                </span>
            </Button>
        </div>
    );
};

// Helper function for button styling
const buttonStyle = (isCollapsed) => ({
    color: '#ffffff',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '1rem',
    textAlign: 'left',
    padding: '0',
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'width 0.3s ease',
});

export default Sidebar;
