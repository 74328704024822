export function speedToString(s) {
    let unit = 'bps';
    if (s >= 1_000_000_000) {
        unit = 'Gbps';
        s /= 1_000_000_000
    } else if (s >= 1_000_000) {
        unit = 'Mbps';
        s /= 1_000_000
    } else if (s >= 1000) {
        unit = 'Kbps';
        s /= 1000
    }
    return s + ' ' + unit
}
