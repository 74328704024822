import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";
import {speedToString} from "../Helpers/Units";

export const CreatePolicy = ({ tenantId }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [scheduleType, setScheduleType] = useState('businessHours')
    const [backupInterval, setBackupInterval] = useState('2h');
    const [startTime, setStartTime] = useState('08:00');
    const [endTime, setEndTime] = useState('18:00');
    const [dayMon, setDayMon] = useState(true);
    const [dayTue, setDayTue] = useState(true);
    const [dayWed, setDayWed] = useState(true);
    const [dayThu, setDayThu] = useState(true);
    const [dayFri, setDayFri] = useState(true);
    const [daySat, setDaySat] = useState(false);
    const [daySun, setDaySun] = useState(false);
    const [inclusions, setInclusions] = useState('');
    const [exclusions, setExclusions] = useState('');
    const [throttlingType, setThrottlingType] = useState('none')
    const [thrSpeed, setThrSpeed] = useState(10_000_000);
    const [thrStartTime, setThrStartTime] = useState('02:00');
    const [thrEndTime, setThrEndTime] = useState('21:00');
    const [thrDayMon, setThrDayMon] = useState(true);
    const [thrDayTue, setThrDayTue] = useState(true);
    const [thrDayWed, setThrDayWed] = useState(true);
    const [thrDayThu, setThrDayThu] = useState(true);
    const [thrDayFri, setThrDayFri] = useState(true);
    const [thrDaySat, setThrDaySat] = useState(false);
    const [thrDaySun, setThrDaySun] = useState(false);
    const [retention, setRetention] = useState('7y');

    const handleSavePolicy = () => {
        let policy = {
            tenantId: "ab0d68ce-1eec-41ee-baef-903c295ed526",
            name,
            description,
            exclusions: exclusions.split(';').filter(i => i.trim() !== ''),
            inclusions: inclusions.split(';').filter(i => i.trim() !== ''),
            schedule: {
                type: scheduleType,
                config: {
                    startTime,
                    endTime,
                    interval: backupInterval,
                    daysOfTheWeek: {
                        mon: dayMon,
                        tue: dayTue,
                        wed: dayWed,
                        thu: dayThu,
                        fri: dayFri,
                        sat: daySat,
                        sun: daySun,
                    }
                }
            },
            throttling: {
                type: throttlingType,
                config: {
                    speed: thrSpeed,
                    startTime: thrStartTime,
                    endTime: thrEndTime,
                    daysOfTheWeek: {
                        mon: thrDayMon,
                        tue: thrDayTue,
                        wed: thrDayWed,
                        thu: thrDayThu,
                        fri: thrDayFri,
                        sat: thrDaySat,
                        sun: thrDaySun,
                    },
                }
            },
            retention
        };

        axios.post(`/api/v1/tenants/${tenantId}/policies`, policy)
            .then(() => {
                alert("Policy creation request sent");

            })
            .catch(error => {
                console.error('Error creating policy', error);
                alert("Failed to create policy");
            });
    };

    return (
        <Container>
            <h1>Create New Policy</h1>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formName">
                    <Form.Label column sm={2}>Name</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type = "text"
                            value = {name}
                            onChange = {(e) => setName(e.target.value)}
                        ></Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formDescription">
                    <Form.Label column sm={2}>Description</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type = "text"
                            value = {description}
                            onChange = {(e) => setDescription(e.target.value)}
                        ></Form.Control>
                    </Col>
                </Form.Group>

                <h1>Schedule</h1>
                <Form.Group as={Row} className="mb-3" controlId="formScheduleType">
                    <Form.Label column sm={2}>Schedule Type</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as = "select"
                            value = {scheduleType}
                            onChange = {(e) => setScheduleType(e.target.value)}
                        >
                            <option value="demoEveryMinute">DEMO every minute 🖖</option>
                            <option value="demoEvery5minutes">DEMO every 5 minutes 🖖</option>
                            <option value="interval">Interval</option>
                            <option value="businessHours">Business hours</option>
                            <option value="custom">Custom</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                { (scheduleType === 'custom' || scheduleType === 'interval') &&
                <Form.Group as={Row} className="mb-3" controlId="formScheduleInterval">
                    <Form.Label column sm={2}>Backup Interval</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as = "select"
                            value = {backupInterval}
                            onChange = {(e) => setBackupInterval(e.target.value)}
                        >
                            <option value="1h">Every hour</option>
                            <option value="2h">Every 2 hours</option>
                            <option value="4h">Every 4 hours</option>
                            <option value="6h">Every 6 hours</option>
                            <option value="8h">Every 8 hours</option>
                            <option value="12h">Every 12 hours</option>
                            <option value="24h">Once per day</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                }
                { scheduleType === 'custom' && <>
                <Form.Group as={Row} className="mb-3" controlId="formScheduleStartTime">
                    <Form.Label column sm={2}>Start Time</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                        >
                            { Array(24).fill().map((e, i) =>
                                <option value={(i + ':00').padStart(5, '0')} key={i}>{(i % 12 === 0 ? 12 : i % 12)}:00 {i >= 12 ? 'PM' : 'AM'}</option>
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formScheduleEndTime">
                    <Form.Label column sm={2}>End Time</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                        >
                            { Array(24).fill().map((e, i) =>
                                <option value={(i + ':00').padStart(5, '0')} key={i}>{(i % 12 === 0 ? 12 : i % 12)}:00 {i >= 12 ? 'PM' : 'AM'}</option>
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formScheduleDays">
                    <Form.Label column sm={2}>Days</Form.Label>
                    <Col sm={10}>
                        <Form.Check
                            type="checkbox"
                            checked={dayMon}
                            onChange={(e) => setDayMon(e.target.checked)}
                            label="Monday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={dayTue}
                            onChange={(e) => setDayTue(e.target.checked)}
                            label="Tuesday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={dayWed}
                            onChange={(e) => setDayWed(e.target.checked)}
                            label="Wednesday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={dayThu}
                            onChange={(e) => setDayThu(e.target.checked)}
                            label="Thursday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={dayFri}
                            onChange={(e) => setDayFri(e.target.checked)}
                            label="Friday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={daySat}
                            onChange={(e) => setDaySat(e.target.checked)}
                            label="Saturday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={daySun}
                            onChange={(e) => setDaySun(e.target.checked)}
                            label="Sunday"
                        />
                    </Col>
                </Form.Group>
                </>}

                <h1>Scope</h1>
                <Form.Group as={Row} className="mb-3" controlId="forminclusions">
                    <Form.Label column sm={2}>Inclusions</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            placeholder="eg: C:/Users/abcd - use ; as separator"
                            value={inclusions}
                            onChange={(e) => setInclusions(e.target.value)}
                        />

                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formexclusions">
                    <Form.Label column sm={2}>Exclusions</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            placeholder="eg: C:/Users/abcd/def - use ; as separator"
                            value={exclusions}
                            onChange={(e) => setExclusions(e.target.value)}
                        />

                    </Col>
                </Form.Group>

                <h1>Throttling</h1>
                <Form.Group as={Row} className="mb-3" controlId="formThrottlingType">
                    <Form.Label column sm={2}>Throttling Type</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as = "select"
                            value = {throttlingType}
                            onChange = {(e) => setThrottlingType(e.target.value)}
                        >
                            <option value="none">none</option>
                            <option value="always">always</option>
                            <option value="businessHours">Business hours</option>
                            <option value="custom">Custom</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                { throttlingType !== 'none' &&
                <Form.Group as={Row} className="mb-3" controlId="formThrottlingSpeed">
                    <Form.Label column sm={2}>Throttling Speed</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={thrSpeed}
                            onChange={(e) => setThrSpeed(parseInt(e.target.value))}
                        >
                            <option value="1000">{speedToString(1000)}</option>
                            { Array(7).fill().map((e, i) => {
                                    const bps = 5 * Math.pow(10, i + 3);
                                    return <>
                                    <option value={bps}>{speedToString(bps)}</option>
                                    <option value={2 * bps}>{speedToString(2 * bps)}</option>
                                    </>
                                }
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                }
                { throttlingType === 'custom' && <>
                <Form.Group as={Row} className="mb-3" controlId="formThrottlingStartTime">
                    <Form.Label column sm={2}>Start Time</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={thrStartTime}
                            onChange={(e) => setThrStartTime(e.target.value)}
                        >
                            { Array(24).fill().map((e, i) =>
                                <option value={(i + ':00').padStart(5, '0')} key={i}>{(i % 12 === 0 ? 12 : i % 12)}:00 {i >= 12 ? 'PM' : 'AM'}</option>
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formThrottlingEndTime">
                    <Form.Label column sm={2}>End Time</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={thrEndTime}
                            onChange={(e) => setThrEndTime(e.target.value)}
                        >
                            { Array(24).fill().map((e, i) =>
                                <option value={(i + ':00').padStart(5, '0')} key={i}>{(i % 12 === 0 ? 12 : i % 12)}:00 {i >= 12 ? 'PM' : 'AM'}</option>
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formThrottlingDays">
                    <Form.Label column sm={2}>Days</Form.Label>
                    <Col sm={10}>
                        <Form.Check
                            type="checkbox"
                            checked={thrDayMon}
                            onChange={(e) => setThrDayMon(e.target.checked)}
                            label="Monday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDayTue}
                            onChange={(e) => setThrDayTue(e.target.checked)}
                            label="Tuesday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDayWed}
                            onChange={(e) => setThrDayWed(e.target.checked)}
                            label="Wednesday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDayThu}
                            onChange={(e) => setThrDayThu(e.target.checked)}
                            label="Thursday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDayFri}
                            onChange={(e) => setThrDayFri(e.target.checked)}
                            label="Friday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDaySat}
                            onChange={(e) => setThrDaySat(e.target.checked)}
                            label="Saturday"
                        />
                        <Form.Check
                            type="checkbox"
                            checked={thrDaySun}
                            onChange={(e) => setThrDaySun(e.target.checked)}
                            label="Sunday"
                        />
                    </Col>
                </Form.Group>
                </> }

                <h1>Retention</h1>
                <Form.Group as={Row} className="mb-3" controlId="formRetention">
                    <Form.Label column sm={2}>Retention</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            as="select"
                            value={retention}
                            onChange={(e) => setRetention(e.target.value)}
                        >
                            <option value="90d">90 days</option>
                            <option value="1y">1 year</option>
                            <option value="3y">3 years</option>
                            <option value="7y">7 years</option>
                            <option value="inf">Infinite</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button variant="primary" onClick={handleSavePolicy}>Save Policy</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );
};

