import {useEffect, useState} from "react";
import {Button, Table} from "react-bootstrap";

const ListTenants = ({onSelectTenant}) => { // Accept the tenant selection handler as a prop
    const [tenants, setTenants] = useState([]);

    // Fetch tenants when the component mounts
    useEffect(() => {
        fetch('/internalapi/v1/tenants') // API to fetch the tenants
            .then(response => {
                if (!response.ok) {
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => { setTenants(data); })
            .catch(error => console.error('Error fetching tenants:', error));
    }, []);

    return (
        <div className="container">
            <h1>Select a Tenant</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tenant ID</th>
                        <th>Control Deployment</th>
                        <th>Dashboard API</th>
                        <th>Action</th> {/* New column for selecting tenant */}
                    </tr>
                </thead>
                <tbody>
                    {tenants.map((tenant) => (
                        <tr key={tenant.id}>
                            <td>{tenant.id}</td>
                            <td>{tenant.controlDeployment}</td>
                            <td>{tenant.dashboardApi}</td>
                            <td>
                                {/* Button to select tenant */}
                                <Button onClick={() => onSelectTenant(tenant.id)}>
                                    Select
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default ListTenants;

