import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";

export const CreateLicensePool = ({tenantId}) => {
    const [licenseType, setLicenseType] = useState('K365');
    const [totalLicenses, setTotalLicenses] = useState(0);
    // Function to handle incrementing/decrementing total licenses
    const incrementLicenses = () => setTotalLicenses(prev => prev + 1);
    const decrementLicenses = () => setTotalLicenses(prev => Math.max(0, prev - 1));  // Make sure it doesn't go below 0
    const handleSavePool= () => {
        let license = {
            licenseType: licenseType,
            baseQuantity: totalLicenses,
        };

        axios.post(`/internalapi/v1/tenants/${tenantId}/licenses`, license)
            .then(() => {
                alert("License Pool creation request sent");

            })
            .catch(error => {
                console.error('Error creating license pool', error);
                alert("Failed to create license pool");
            });
    };

    return (
        <Container>
            <h1>Create New License Pool</h1>
            <Form>
                <h3>License Type</h3>
                <Form.Group as={Row} className="mb-3" controlId="formLicenseType">
                    <Form.Label column sm={2}>License Type</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            value={licenseType}
                            onChange={(e) => setLicenseType(e.target.value)}
                        >
                        </Form.Control>
                    </Col>
                </Form.Group>

                <h3>Total Licenses</h3>
                <Form.Group as={Row} className="mb-3" controlId="formTotalLicense">
                    <Form.Label column sm={2}>Total Licenses</Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            type="number"
                            value={totalLicenses}
                            onChange={(e) => setTotalLicenses(Math.max(0, Number(e.target.value)))} // Prevent negative input
                        />
                    </Col>
                    <Col sm={2}>
                        <Button variant="outline-success" onClick={incrementLicenses}>+</Button>
                        <Button variant="outline-danger" onClick={decrementLicenses}>-</Button>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button variant="primary" onClick={handleSavePool}>Save Policy</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );
};