import React, { useEffect, useState } from 'react';
import {Button, Table} from "react-bootstrap";
import axios from 'axios';
import {UpdateLicensePool} from './UpdateLicensePool';


const ListLicenses = ({tenantId}) => {
    const [licenses, setLicenses] = useState([]);
    const [isUpdateLicenseModalOpen, setIsUpdateLicenseModalOpen] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState({});


    const openUpdateLicenseModal = (license) => {
        console.log(license)
        setSelectedLicense(license)
        setIsUpdateLicenseModalOpen(true)
    }

    const closeUpdateLicenseModal = (license) => {
        setSelectedLicense(license)
        setIsUpdateLicenseModalOpen(false)
    }

    const handleDeleteLicensePool = (license) => {

        axios.delete(`/internalapi/v1/tenants/${tenantId}/licenses/${license.licenseType}`)
            .then(() => {
                alert("license pool deleted");
            })
            .catch(error => {
                console.error('Error deleting license pool', error);
                alert("Failed to delete license pool");
            });
    };

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/licenses`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {setLicenses(data);})
            .catch(error => console.error('Error fetching licenses:', error));
    }, []);

    return (
        <div className="container">
            <h1>List of Licenses</h1>
            <Table striped bordered hover >
                <thead>
                <tr>
                    <th>License Type</th>
                    <th>Total Licenses</th>
                    <th>Used Licenses</th>
                    <th>Created Time</th>
                    <th>Expiry Time</th>
                    <th>Update License Pool</th>

                </tr>
                </thead>
                <tbody>
                {licenses.map(license => (
                    <tr key = {license.licenseType}>
                        <td>{license.licenseType}</td>
                        <td>{license.totalLicenses}</td>
                        <td>{license.usedLicenses}</td>
                        <td>{license.createdTime}</td>
                        <td>{license.expiryTime}</td>
                        <td><Button variant = "success"
                                    size = "sm"
                                    style = {{
                                        padding: "0.25rem 0.5rem", // Smaller padding for a more compact button
                                        fontSize: "0.8rem" // Smaller font size for a more compact button
                                    }}
                                    onClick = {() => openUpdateLicenseModal(license)}>Update License Pool</Button>
                        </td>
                        <td><Button size = "sm"
                                    style = {{margin: "1rem auto", backgroundColor: "#dee2e6", border: "none"}}
                                    onClick = {() => handleDeleteLicensePool(license)}>🗑️</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {isUpdateLicenseModalOpen && selectedLicense && <UpdateLicensePool closeModal={closeUpdateLicenseModal} selectedLicense = {selectedLicense} tenantId = {tenantId}/>}
        </div>
    );
};

export default ListLicenses;
