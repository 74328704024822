import React from "react";
import { Badge } from "react-bootstrap";

export const BackupProgress = ({ progressTask }) => {
    // Check if progressTask.progress is null or empty
    if (!progressTask.progress || Object.keys(progressTask.progress).length === 0) {
        return null; // Render nothing if progressTask.progress is empty or undefined
    }

    // Conditionally increment volumeId if volumeCount > 0
    const displayedVolumeId = progressTask.progress.volumeCount > 0
        ? progressTask.progress.volumeId + 1
        : progressTask.progress.volumeId;

    return (
        <React.Fragment>
            <Badge bg="dark">
                <h4>Volume:</h4>
                <h5>{displayedVolumeId}/{progressTask.progress.volumeCount}</h5>
            </Badge>
            <Badge bg="success">
                <h4>{progressTask.progress.volumeName}</h4>
                <h5>{(progressTask.progress.volumePercentage).toFixed(2)} %</h5>
            </Badge>
            <span className="visually-hidden">unread messages</span>
        </React.Fragment>
    );
};
