import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";
import Modal from "react-bootstrap/Modal";

export const UpdatePolicyForAsset = ({closeModal, selectedAsset, tenantId}) => {
    const [policies, setPolicies] = useState([]);
    const [newPolicy, setNewPolicy] = useState(selectedAsset.policyId)
    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/policies`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {setPolicies(data);})
            .catch(error => console.error('Error fetching policies:', error));
    }, []);

    const handleUpdatePolicy = () => {
        let policy = {
            policyId: newPolicy
        };

        axios.patch(`/api/v1/tenants/${tenantId}/assets/${selectedAsset.id}`, policy)
            .then(() => {
                alert("Policy update for asset sent");

            })
            .catch(error => {
                console.error('Error Updating policy', error);
                alert("Failed to update policy");
            });
        closeModal()
    };

    return (
        <Container>

            <Modal show={true} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update policy for asset</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form.Group as={Row} className="mb-3" controlId="formAssetId">
                        <Form.Label column sm={2}>asset id</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedAsset.id}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPoliciesUpdate">
                            <Form.Label column sm={2}>policies</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as = "select"
                                    value = {newPolicy}
                                    onChange = {(e) => setNewPolicy(e.target.value)}
                                >
                                    {policies.map(policy => (
                                        <option key = {policy.id}>
                                            {policy.id}
                                        </option>
                                    ))}
                                    {/* Add more options as needed */}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdatePolicy}>
                        update policy
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};
