import {Button, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import {generateUUID} from "../Helpers/Uuid";

export const CreateToken = ({closeModal, policyId, tenantId}) => {
    const [organizationId, setOrganizationId] = React.useState('');
    const [storageLocation, setStorageLocation] = React.useState(null);
    const [licenseType, setLicenseType] = React.useState(null);

    const [organizations, setOrganizations] = React.useState([]);
    const [storageLocations, setStorageLocations] = React.useState([]);
    const [licenses, setLicenses] = React.useState([]);

    const handleSaveToken = () => {
        let token = {
            organizationId,
            policyId,
            storageLocation,
            licenseType,
        };
        if (!token.organizationId) {
            token.organizationId = generateUUID();
        }

        axios.post(`/api/v1/tenants/${tenantId}/tokens`, token)
            .then(() => {
                alert("Token creation request sent");
            })
            .catch(error => {
                console.error('Error creating token', error);
                alert("Failed to create token");
            });
    };

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/tokens`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {
                let orgs = data.map(token => token.organizationId);
                let unique = orgs.filter((v, i) => orgs.indexOf(v) === i);
                setOrganizations(unique);
                unique[0] && setOrganizationId(unique[0]);
                console.log(unique);
            })
            .catch(error => console.error('Error fetching deployment tokens', error));
    }, []);

    useEffect(() => {
        fetch('/api/v1/storageLocations')
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {
                setStorageLocations(data);
                setStorageLocation(data[0]);
                console.log(data);
            })
            .catch(error => console.error('Error fetching storage locations', error));
    }, []);

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/licenses`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {
                setLicenses(data);
                data[0] && setLicenseType(data[0].licenseType);
                console.log(data);
            })
            .catch(error => console.error('Error fetching licenses', error));
    }, []);

    return (
        <Container>
            <Modal show={true} onHide={closeModal}>

                <Modal.Header closeButton>
                    <Modal.Title>Create Deployment Token for Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="md-3" controlId="formOrg">
                            <Form.Label column sm={2}>Organization UUID</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as="select"
                                    value={organizationId}
                                    onChange={(e) => setOrganizationId(e.target.value)}
                                >
                                    <option value={""} key="new">Create a new organization</option>
                                    {organizations.map(org => (
                                        <option value={org} key={org}>{org}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formStorage">
                            <Form.Label column sm={2}>Storage Location</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as = "select"
                                    value = {storageLocation}
                                    onChange = {(e) => setStorageLocation(e.target.value)}
                                >
                                    {storageLocations.map(location => (
                                        <option value={location} key={location}>{location}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="md-3" controlId="formLicense">
                            <Form.Label column sm={2}>License Type</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    as = "select"
                                    value = {licenseType}
                                    onChange = {(e) => setLicenseType(e.target.value)}
                                >
                                    {licenses.map(license => (
                                        <option value={license.licenseType} key={license.licenseType}>{license.licenseType} ({license.totalLicenses-license.usedLicenses}/{license.totalLicenses} available)</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveToken}>
                        Create Token
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}
