import {  Button, Row, Col, Container } from 'react-bootstrap';
import ListLicenses from "./ListLicenses";
import {CreateLicensePool}  from "./CreateLicensePool";
import { useState } from "react";

const LicenseContainer = ({tenantId}) => {
    const [modalLicenseOpen, setModalLicenseOpen] = useState(false);
    return (
        <Container fluid>

            <Row>
                {modalLicenseOpen ? <CreateLicensePool tenantId={tenantId}/> : <ListLicenses tenantId={tenantId}/>}
            </Row>
            <Row>
                <Col><Button variant="primary" onClick={() => {setModalLicenseOpen(!modalLicenseOpen)} }>
                    { modalLicenseOpen ? "back" : "Create License Pool"}
                </Button></Col>
            </Row>
        </Container>
    );
}

export default LicenseContainer