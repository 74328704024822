import {speedToString} from "../Helpers/Units";

export const ThrottlingTarget = ({ asset }) => {
    let tt = asset?.throttlingTarget;
    if (tt === 0) {
        return <p>no throttling</p>
    } else if (tt !== null && tt !== undefined) {
        return <p>throttling at { speedToString(tt) }</p>
    }
};
