import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";
import Modal from "react-bootstrap/Modal";

export const PairAsset = ({closeModal, selectedToken, tenantId}) => {
    const [assetId, setAssetId] = useState('');

    const handleAssetIdChange = (event) => {
        const inputValue = event.target.value;
        setAssetId(inputValue);
    };

    const handleAssetPairing = () => {
        axios.post(`/api/v1/tenants/${tenantId}/assets`, {tokenId: selectedToken.id, assetId})

            .then((response) => {
                console.log(response)
            })

            .catch(error => {
                console.error('Error pairing asset', error);
                alert("Failed pair asset token");
            });
        closeModal({...selectedToken, assetId: [assetId] , tokenId: [selectedToken.id]})
    };

    return (
        <Container>

            <Modal show={true} onHide={() => closeModal(selectedToken)}>
                <Modal.Header closeButton>
                    <Modal.Title>Pair Asset</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form.Group as={Row} className="mb-3" controlId="formTokenId">
                        <Form.Label column sm={2}>Token id</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedToken.id}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPolicyId">
                        <Form.Label column sm={2}>Policy id</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedToken.policyId}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formOrganizationId">
                        <Form.Label column sm={2}>Organization id</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedToken.organizationId}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formStorageRegion">
                        <Form.Label column sm={2}>Storage region</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedToken.storageLocation}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formLicenseType">
                        <Form.Label column sm={2}>License type</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={selectedToken.licenseType}
                                disabled={true}
                            />
                        </Col>
                    </Form.Group>

                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formassetid">
                            <Form.Label column sm={2}>AssetId</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    type="text"
                                    placeholder="eg: ABCDEFGH"
                                    value={assetId}
                                    onChange={(e) => handleAssetIdChange(e)}
                                />

                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>closeModal(selectedToken)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAssetPairing}>
                        Pair Asset
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

