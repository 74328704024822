import React, { useEffect, useState } from 'react';
import { Button, Table, Badge, Tooltip, OverlayTrigger, Modal, Form, Row, Col } from "react-bootstrap";
import FileBrowser from "../Restores/FileBrowser";
import { UpdatePolicyForAsset } from "./UpdatePolicyForAsset";
import { BackupProgress } from "../Components/BackupProgress";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import {ThrottlingTarget} from "../Components/ThrottlingTarget";
import { copyToClipboard } from "../Helpers/Clipboard";

const ListAssets = ({ tenantId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false)
    const [selectedAssets, setSelectedAssets] = useState([]);  // Track selected assets
    const [selectedAsset, setSelectedAsset] = useState({})
    const [licenseType, setLicenseType] = useState('');
    const [updatePaused, setUpdatePaused] = useState('-1');
    const [updatePolicyId, setUpdatePolicyId] = useState('');
    const [targetedAgentDailyLogs, setTargetedAgentDailyLogs] = useState(1);
    const [assetTelemetryModal, setAssetTelemetryModal] = useState(false);
    const [assets, setAssets] = useState([]);

    const handleAssetTelemetryFetcherModal = () => {
        setAssetTelemetryModal(!assetTelemetryModal)
    }
    const handleAssetTelemetryModalSubmit = () => {
        if (targetedAgentDailyLogs > 7 || targetedAgentDailyLogs <= 0) {
            alert("Invalid number of days to fetch agent logs. You cannot fetch more 7 days or less than a day of logs")
            return
        }
        let body = {
            "days": targetedAgentDailyLogs
        }

        axios.post(`/api/v1/tenants/${tenantId}/assets/${selectedAsset.id}/telemetry/bundle`, body)
            .then(response => {
                console.log("Hey the agent telemetry information is preparing to be bundled")
                const { downloadUrl } = response.data;  // Extract download URL
                console.log("Bundle ready. Download URL:", downloadUrl);
                // Copy the download URL to the clipboard
                copyToClipboard(downloadUrl)
                alert("Copied to clipboard")
            })
            .catch(error => {
                console.error('Error sending request to agent for telemetry', error);
                alert("Error sending request to agent for telemetry information");
            });
        handleAssetTelemetryFetcherModal()
    }
    const openRecoveryPointsModal = (asset) => {
        setSelectedAsset(asset)
        setIsModalOpen(true)
    }
    const closeRecoveryPointsModal = () => {
        setIsModalOpen(false)
    }

    const openPolicyUpdateModal = (asset) => {
        setSelectedAsset(asset)
        setIsPolicyModalOpen(true)
    }
    const closePolicyUpdateModal = () => {
        setIsPolicyModalOpen(false)
    }


    // Toggle asset selection
    const handleCheckboxChange = (assetId) => {
        setSelectedAssets(prevSelected => {
            if (prevSelected.includes(assetId)) {
                return prevSelected.filter(id => id !== assetId);  // Deselect asset
            } else {
                return [...prevSelected, assetId];  // Select asset
            }
        });
    };

     // Handle modal open/close
     const handleUpdateOpenModal = () => {
        setLicenseType(''); // Reset licenseType
        setUpdatePaused('-1'); // Reset paused state
        setUpdatePolicyId(''); // Reset policyId
        setIsUpdateModalOpen(true);
     }
     
     const handleUpdateCloseModal = () => setIsUpdateModalOpen(false);

    // Handle license type change
    const handleLicenseTypeChange = (e) => {
        setLicenseType(e.target.value);
    };

    const handlePausedChange = (e) => {
        setUpdatePaused(e.target.value);
    };

    const handlePolicyIdChange = (e) => {
        setUpdatePolicyId(e.target.value);
    };

    // Submit the selected assets and license type
    const handleSubmitUpdate = () => {
        const requestData = {
            ids: selectedAssets,
        };

        if (licenseType !== '') {
            requestData.licenseType = licenseType;
        }

        if (updatePaused !== '-1') {
            requestData.paused = updatePaused === '1';
        }
        
        if (updatePolicyId !== '') {
            requestData.policyId = updatePolicyId;
        }
        
        // Send the selected assets and license type to the server
        axios.patch(`/api/v1/tenants/${tenantId}/assets`, requestData)
            .then(response => {
                alert("Assets updated successfully!");
                handleUpdateCloseModal();
                setSelectedAssets([]);  // Clear selected assets
                setLicenseType(''); // Reset licenseType
                setUpdatePaused('-1'); // Reset paused state
                setUpdatePolicyId(''); // Reset policyId
            })
            .catch(error => {
                console.error('Error updating assets', error);
                alert("Error updating assets");
            });
    };

    const fetchAssetLogs = (asset) => {
        setSelectedAsset(asset)
        handleAssetTelemetryFetcherModal()
    }

    const renderStorageUsed = (storageUsed) => {
        if (storageUsed === undefined || storageUsed === null) {
            return "0 GiB"
        }
        let storageInGB = storageUsed/(1024*1024*1024)
        return Number(storageInGB.toFixed(2)) + "GiB"
    }


    const getStatus = (asset) => {
        let lastSeen = asset.lastSeen;
        let task = asset.task;
        const lastSeenTime = new Date(lastSeen).getTime();
        const currentTime = Date.now();
        const timeDifference = (currentTime - lastSeenTime) / 1000; // Difference in seconds
    
        const badgeStyle = {
            fontSize: '1rem',  // Adjust the size here
            padding: '0.5rem', // Add some padding for better readability
        };

        if (asset.licenseExpired) {
            return <Badge bg="danger" style={badgeStyle}>License Expired</Badge>;
        }

        if (asset.paused) {
            return <Badge bg="secondary" style={badgeStyle}>Paused</Badge>;
        }

        // Check if lastSeen is valid and time difference is greater than 120 seconds
        if (lastSeen && timeDifference > 120) {
            return <Badge bg="warning" style={badgeStyle}>Offline</Badge>;
        }
    
        // If lastSeen is less than 120 seconds old
        if (lastSeen && timeDifference <= 120) {
            if (task?.type === "RESTORE") {
                if (task.progress && task.progress.totalFiles > 0) {
                    return (
                        <>
                            <Badge bg="success" style={badgeStyle}>
                                      <span className="spinner-grow spinner-grow-sm text-info" role="status">
                                        📝
                                </span><br></br>

                                Restoring... {task.progress.processedFiles}/{task.progress.totalFiles}

                            </Badge>
                        </>
                    )
                }
                return (
                    <>
                        <Badge bg="info" style={badgeStyle}>
                            Calculating..
                            <span className="spinner-grow spinner-grow-sm text-info" role="status">
                                    🧮
                                </span>
                        </Badge>
                    </>
                );
            } else if (task?.type === "BACKUP") {
                return <>
                    <BackupProgress progressTask={task} />
                    <ThrottlingTarget asset={asset} />
                </>;
            } else {
                // Show lastSeen status if no task exists
                return <>
                    <Badge bg="success" style={badgeStyle}>Online</Badge>
                    <ThrottlingTarget asset={asset} />
                </>
            }
        }
    
        // Fallback to show Offline if none of the above conditions match
        return <Badge bg="warning"  style={badgeStyle}>Offline</Badge>;
    };

    const deleteAnAsset = (asset, idx) => {
        // Add a confirmation step
        const confirmed = window.confirm(`Are you sure you want to delete the asset: ${asset.name}?`);
    
        // If the user confirms, proceed with deletion
        if (confirmed) {
            axios.delete(`/api/v1/tenants/${tenantId}/assets/${asset.id}`)
                .then(() => {
                    alert("Request to delete the asset sent.");
                    
                    // Filter out the deleted asset by its index
                    const updatedAssets = assets.filter((_, index) => index !== idx);
                    
                    // Update the state with the new array without the deleted asset
                    setAssets(updatedAssets);
                    
                    // Optionally force a re-render (if the UI is still not updating)
                    setTimeout(() => {}, 0);
                })
                .catch(error => {
                    console.error('Error deleting asset', error);
                    alert("Error deleting asset");
                });
        } else {
            // If user cancels, do nothing
            console.log("Deletion canceled by the user.");
        }
    };

    const pauseOrResumeAnAsset = (asset, idx) => {


        axios.patch(`/api/v1/tenants/${tenantId}/assets/${asset.id}`,
            {
                paused: !asset.paused
            }
        )
            .then(() => {

                if (asset.paused) {
                    alert("Request to resume the asset sent.");
                } else {
                    alert("Request to pause the asset sent.")
                }
                const updatedAssets = assets.map((a, index) =>
                    index === idx ? { ...a, paused: !a.paused } : a
                );

                // Update the state with the new array
                setAssets(updatedAssets);
            })
            .catch(error => {
                console.error('Error Pausing/Resume asset', error);
                alert("Error Pausing/Resume asset");
            });
    }

    const onDemandBackupAnAsset = (asset) => {

        axios.post(`/api/v1/tenants/${tenantId}/assets/${asset.id}/recoveryPoints`)
            .then(() => {
                alert("Request to backup the asset sent.")
            })
            .catch(error => {
                console.error('Error queueing backup for asset', error);
                alert("Error queueing backup for asset");
            });
    }

    useEffect(() => {

        const fetchData = async () => {
            fetch(`/internalapi/v1/tenants/${tenantId}/assets`)
                .then(response => {
                    if (!response.ok) {
                        // Handle HTTP errors
                        if (response.status === 500) {
                            throw new Error('Server error: 500');
                        } else {
                            throw new Error(`Error: ${response.status}`);
                        }
                    }
                    return response.json();
                })
                .then(data => {
                    setAssets(data); console.log(data);
                })
                .catch(error => {
                    console.error('Error fetching assets:', error);
                    // Optional: You could also set an error state here to display an error message in your UI
                });
        }
        fetchData();
        const interval = setInterval(fetchData, 30000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const renderTooltip = (props, value) => (
        <Tooltip id="button-tooltip" {...props}>
            {value}
        </Tooltip>
    );

    const tableCellStyle = { padding: "1rem", alignItems: "center" };

    const formatDate = (date) => date ? new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
        hour12: true
    }) : <p>-</p>;

    return (
        <div className="container">
            <h1>List of Assets</h1>
            {/* Conditionally render button if assets are selected */}
                {selectedAssets.length > 0 && (
                <Button 
                    variant="primary" 
                    onClick={handleUpdateOpenModal} 
                    style={{ float: 'right', marginBottom: '10px' }}
                >
                    Update Assets
                </Button>
            )}
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Select</th>
                    <th>Asset ID</th>
                    <th>Asset Name</th>
                    <th>License Type</th>
                    <th>OS</th>
                    <th>Policy ID</th>
                    <th>Organization ID</th>
                    <th>Status</th>
                    <th>Last Successful Backup</th>
                    <th>Agent Version</th>
                    <th>Storage used</th>
                    <th>Pause/Restore/Delete</th>

                </tr>
                </thead>
                <tbody>
                { assets!== null && assets.length !==0
                    && assets
                        .filter(asset => asset.name)
                        .map((asset, idx) => (

                    <tr key = {asset.id}>
                        <td>
                            <input 
                                type="checkbox" 
                                checked={selectedAssets.includes(asset.id)} 
                                onChange={() => handleCheckboxChange(asset.id)} 
                            />
                        </td>
                        <td style={tableCellStyle}>{asset.id}</td>
                        <td style={tableCellStyle}>{asset.name}</td>
                        <td style={tableCellStyle}>{asset.licenseType}</td>
                        <td style = {tableCellStyle}>{asset.os}</td>
                        <td style = {tableCellStyle}>
                            <span style = {{marginRight: "1rem"}}>
                                {asset.policyId}
                            </span>
                            <Button
                                variant = "primary"
                                size = "sm"
                                style = {{
                                    padding: "0.25rem 0.5rem", // Smaller padding for a more compact button
                                    fontSize: "0.8rem" // Smaller font size for a more compact button
                                }}
                                onClick = {() => openPolicyUpdateModal(asset)}
                            >
                                Update
                            </Button>
                        </td>
                        <td style = {tableCellStyle}>{asset.organizationId}</td>
                        <td style = {tableCellStyle}> 
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => renderTooltip(props, formatDate(asset["lastSeen"]))}
                            >
                                <div>{getStatus(asset)}</div>
                            </OverlayTrigger></td>
                        <td style = {tableCellStyle}> {formatDate(asset["lastSuccessfulBackup"])}</td>
                        <td style = {tableCellStyle}> {asset.agentVersion}</td>
                        <td style = {tableCellStyle}> {renderStorageUsed(asset.storageUsed)}</td>
                        <td style = {tableCellStyle}>
                                <DropdownButton title="options" id="bg-nested-dropdown" variant = "info" size = "sm" style = {{margin: "1rem auto", fontSize: "49px"}}>
                                    <Dropdown.Item eventKey="1" onClick = {() => pauseOrResumeAnAsset(asset, idx)} >{asset.paused ? "Resume" : "Pause"}</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick = {() => openRecoveryPointsModal(asset)} >Restore </Dropdown.Item>
                                    <Dropdown.Item eventKey="3" onClick = {() => onDemandBackupAnAsset(asset)} >Backup </Dropdown.Item>
                                    <Dropdown.Item eventKey="4" onClick = {() => deleteAnAsset(asset, idx)} >Delete Asset </Dropdown.Item>
                                    <Dropdown.Item eventKey="5" onClick = {() => fetchAssetLogs(asset)} >Get logs </Dropdown.Item>
                                </DropdownButton>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {/* Modal for getting agent telemetry information of an asset */}
            <Modal show={assetTelemetryModal} onHide={handleAssetTelemetryFetcherModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Fetch agent telemetry information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        as = "select"
                        value = {targetedAgentDailyLogs}
                        onChange = {(e) => setTargetedAgentDailyLogs(parseInt(e.target.value))}
                        style={{width: "20%"}}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleAssetTelemetryFetcherModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAssetTelemetryModalSubmit}>
                        Get logs
                    </Button>
                </Modal.Footer>
            </Modal>
             {/* Modal for selecting license type */}
             <Modal show={isUpdateModalOpen} onHide={handleUpdateCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Assets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    {/* License Type Selection */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>License Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control 
                                type="text" 
                                value={licenseType} 
                                onChange={handleLicenseTypeChange}
                            >
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    {/* Paused Selection */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>Paused</Form.Label>
                        <Col sm={8}>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                name="paused"
                                value="1"
                                checked={updatePaused === "1"}
                                onChange={handlePausedChange}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                name="paused"
                                value="0"
                                checked={updatePaused === "0"}
                                onChange={handlePausedChange}
                            />
                        </Col>
                    </Form.Group>

                    {/* Policy ID Selection */}
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}>Policy ID</Form.Label>
                        <Col sm={8}>
                        <Form.Control 
                                type="text" 
                                value={updatePolicyId} 
                                onChange={handlePolicyIdChange}
                            >
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleUpdateCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmitUpdate}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            {isModalOpen && <FileBrowser closeModal = {closeRecoveryPointsModal} assetId = {selectedAsset.id} tenantId={tenantId}/>}
            {isPolicyModalOpen &&
                <UpdatePolicyForAsset closeModal = {closePolicyUpdateModal} selectedAsset = {selectedAsset} tenantId={tenantId}/>}

        </div>
    );
};

export default ListAssets;
