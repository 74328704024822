import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {generateUUID} from "../Helpers/Uuid";

export const CreateTenant = () => {
    const [tenantId, setTenantId] = useState('');
    const [controlDeployment, setControlDeployment] = useState('');
    const [dashboardApi, setDashboardApi] = useState('');

    const handleSaveTenant = () => {
        const tenant = {
            id: tenantId,
            controlDeployment,
            dashboardApi,
        };
        axios.post('/internalapi/v1/tenants', tenant)
            .then(() => {
                alert("Tenant creation request sent");

            })
            .catch(error => {
                console.error('Error creating tenant', error);
                alert("Failed to create tenant");
            });
    }

    const randomizeName = () => {
        setTenantId(generateUUID());
    };

    useEffect(() => {
        randomizeName();
    }, []);

    return (
        <Container>
            <h1>Create New Tenant</h1>
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formId">
                    <Form.Label column sm={2}>Tenant ID</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            value={tenantId}
                            onChange={(e) => setTenantId(e.target.value)}
                        />
                    </Col>
                    <Button onClick={randomizeName} variant="primary">Roll the dice</Button>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formControlDeployment">
                    <Form.Label column sm={2}>Control Deployment</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            value={controlDeployment}
                            onChange={(e) => setControlDeployment(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formDashboardApi">
                    <Form.Label column sm={2}>Dashboard API</Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            value={dashboardApi}
                            onChange={(e) => setDashboardApi(e.target.value)}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                        <Button variant="primary" onClick={handleSaveTenant}>Save Tenant</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );
}
