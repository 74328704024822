export const FileInclusionState = {
    FULL_INCLUDED: 1,
    FULL_EXCLUDED: 2,
    PARTIAL_INCLUDED: 3
}
class TrieNode {
    constructor(parent = new TrieNode(null)) {
        this.subDirectory= {};
        this.parent = parent;
        this.inclusionState = FileInclusionState.FULL_EXCLUDED
        this.data = null;
        this.volume = null
    }
}

class Trie {
    constructor() {
        this.root = new TrieNode();
        this.root.data = {
            "name": "",
            "path": ""
        }
    }

    // directoryPath is of type C:\abcd\users\xyz
    insert(directoryPath, volume = null, data = null) {
        let node = this.root;
        for (let dir of directoryPath.split("\\")) {
            if (dir === "") {
                // There can be sometimes be C:\\\\ so we skip empty strings.
                continue
            }
            if (!node.subDirectory[dir]) {
                node.subDirectory[dir] = new TrieNode(node);
                if (node.inclusionState === FileInclusionState.FULL_INCLUDED) {
                    node.subDirectory[dir].inclusionState = FileInclusionState.FULL_INCLUDED
                }
                node.subDirectory[dir].volume = volume
                node.subDirectory[dir].data = data
            }

            node = node.subDirectory[dir];
        }

    }

    updateDirectoryState(directoryPath, include) {
        let node = this.root;
        for (let dir of directoryPath.split("\\")) {
            if (dir === "") {
                // There can be sometimes be C:\\\\ so we skip empty strings.
                continue
            }

            node = node.subDirectory[dir];
        }
        if (node === undefined) {
            return
        }
        if (include) {
            node.inclusionState = FileInclusionState.FULL_INCLUDED
        } else {
            node.inclusionState = FileInclusionState.FULL_EXCLUDED
        }

        this.updateSubDirectoryState(node,include)
        this.updateParentDirectoryState(node,include)
    }

    updateSubDirectoryState(node,include) {
        // For subdirectories inclusion will always be either full or none. i.e we can never
        // set partial state of children directories, it's either all are included or all are excluded.
        for(let child of Object.keys(node.subDirectory)) {
            let childNode = node.subDirectory[child]
            if (include) {
                childNode.inclusionState = FileInclusionState.FULL_INCLUDED
            } else {
                childNode.inclusionState = FileInclusionState.FULL_EXCLUDED
            }
            this.updateSubDirectoryState(childNode,include)
        }
    }

    updateParentDirectoryState(node) {
        let parent = node.parent
        while (parent != null ){
            this.updateDirectoryInclusionState(parent)
            parent = parent.parent
        }
    }

    getDirectoryNode(directoryPath) {
        let node = this.root;
        for (let dir of directoryPath.split("\\")) {
            if (dir === "") {
                // There can be sometimes be C:\\\\ so we skip empty strings.
                continue
            }

            node = node.subDirectory[dir];
        }
        if (node === undefined) {
            return
        }
        return node
    }

    updateDirectoryInclusionState(node) {
        if (node === undefined || node === null) {
            return;
        }

        let pastInclusionStates = [];

        for(let child of Object.keys(node.subDirectory)) {
            let childNode = node.subDirectory[child]
            if (!pastInclusionStates.includes(childNode.inclusionState)) {
                pastInclusionStates.push(childNode.inclusionState)
            }
        }
        if (pastInclusionStates.length >1 ) {
            // since subdirectorys aren't fully included nor fully excluded, we have to make the parent as partial too.
            node.inclusionState = FileInclusionState.PARTIAL_INCLUDED
        } else {
            node.inclusionState = pastInclusionStates[0]
        }
    }
}

export const  buildInclusionPaths = (node, currentPath = [], resultSet = []) => {
    if (node === undefined || node === null) {
        return
    }
    for(let child of Object.keys(node.subDirectory)) {
        let childNode = node.subDirectory[child]
        if (childNode.inclusionState === FileInclusionState.FULL_INCLUDED) {
            currentPath.push(child)
            resultSet.push(processPathForAPI(currentPath, childNode.volume.mountpoint))
            currentPath.pop()
        } else if (childNode.inclusionState === FileInclusionState.PARTIAL_INCLUDED) {
            currentPath.push(child)
            buildInclusionPaths(childNode, currentPath, resultSet)
            currentPath.pop()
            // Since this directory isn't fully included nor excluded, we dig deeper to find specific paths.
        }
    }
}

const processPathForAPI = (currentPath, mountpoint ) => {
    let path = currentPath.join("\\")
    let mountPointIndex = path.indexOf(mountpoint) + mountpoint.length-1;
    return mountpoint + path.substring(mountPointIndex + 1).replaceAll("\\", "/")
}
export default Trie