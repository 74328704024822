import {  Button, Row, Col, Container } from 'react-bootstrap';
import { useState } from "react";
import ListTenants from "./ListTenants";
import {CreateTenant} from "./CreateTenant";

const TenantContainer = ({onSelectTenant}) => {
    const [modalTenantOpen, setModalTenantOpen] = useState(false);
    return (
        <Container fluid>
            <Row>
                {modalTenantOpen ? <CreateTenant /> : <ListTenants onSelectTenant={onSelectTenant}/>}
            </Row>
            <Row>
                <Col><Button variant="primary" onClick={() => {setModalTenantOpen(!modalTenantOpen)} }>
                    { modalTenantOpen ? "back" : "Create Tenant"}
                </Button></Col>
            </Row>
        </Container>
    );
}

export default TenantContainer
