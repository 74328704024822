import React, { useEffect, useState } from 'react';
import {Button, Table} from "react-bootstrap";
import AutohideToast from "../Components/AutohideToast";
import {CreateToken} from "./CreateToken";
import axios from 'axios';
import {UpdatePolicy} from './UpdatePolicy';
import {speedToString} from "../Helpers/Units";

const ListPolicies = ({tenantId}) => {
    const [policies, setPolicies] = useState([]);
    const [showToast, setShowToast] = useState(false)

    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const [isUpdatePolicyModalOpen, setIsUpdatePolicyModalOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState({});

    
    const openUpdatePolicyModal = (policy) => {
        console.log(policy)
        setSelectedPolicy(policy)
        setIsUpdatePolicyModalOpen(true)
    }

    const closeUpdatePolicyModal = (policy) => {
        setSelectedPolicy(policy)
        setIsUpdatePolicyModalOpen(false)
    }

    const daysToString = (days) => {
        const keys = Object.keys(days)
        for (const key of keys) {
            if (!days[key]) {
                delete days[key]
            }
        }

        if (Object.keys(days).length === 7) {
            return 'every day';
        } else {
            let weekdays = ['mon', 'tue', 'wed', 'thu', 'fri'].filter(d => days.hasOwnProperty(d))
            if (weekdays.length === 5) {
                let daysStr = 'weekdays';
                if (days.hasOwnProperty('sat')) {
                    daysStr += ' and Saturday';
                } else if (days.hasOwnProperty('sun')) {
                    daysStr += ' and Sunday';
                }
                return daysStr
            } else {
                let parts = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].filter(d => days.hasOwnProperty(d))
                return parts.join(', ');
            }
        }
    }

    const scheduleToString = (s) => {
        switch (s.type) {
            case 'demoEveryMinute':
                return 'DEMO every minute 🖖';
            case 'demoEvery5minutes':
                return 'DEMO every 5 minutes 🖖';
            case 'interval':
                return 'every ' + s.config.interval;
            case 'businessHours':
                return 'business hours';
        }
        if (s.type !== 'custom') {
            return '⚠ unknown 💔'
        }

        const c = s.config;
        let daysStr = daysToString({...c.daysOfTheWeek})
        return `${c.startTime}-${c.endTime} every ${c.interval} ${daysStr}`;
    }

    const throttlingToString = (t) => {
        switch (t.type) {
            case 'none':
               return 'disabled';
            case 'always':
                return 'always, ' + speedToString(t.config.speed);
            case 'businessHours':
               return 'business hours, ' + speedToString(t.config.speed);
        }
        if (t.type !== 'custom') {
            return '⚠ unknown 💔'
        }

        const c = t.config;
        let daysStr = daysToString({...c.daysOfTheWeek})
        return `${c.startTime}-${c.endTime} on ${daysStr}, ${speedToString(c.speed)}`;
    }

    const retentionToString = (r) => {
        switch (r) {
            case '90d':
                return '90 days';
            case '1y':
                return '1 year';
            case '3y':
                return '3 years';
            case '7y':
                return '7 years';
            case 'inf':
                return 'infinite';
            default:
                return 'unknown 🤷‍♂️'
        }
    }

    const openCreateTokenModal = (policy) => {
        setSelectedPolicy(policy);
        setIsTokenModalOpen(true);
    };

    const closeCreateTokenModal = () => {
        setIsTokenModalOpen(false);
    };

    const handleDeletePolicy = (policy) => {
        axios.delete(`/api/v1/tenants/${tenantId}/policies/${policy.id}`)
            .then(() => {
                alert("Policy deleted");
            })
            .catch(error => {
                console.error('Error deleting policy', error);
                alert("Failed to delete policy");
            });
    };

    useEffect(() => {
        fetch(`/api/v1/tenants/${tenantId}/policies`)
            .then(response => {
                if (!response.ok) {
                    // Handle HTTP errors
                    if (response.status === 500) {
                        throw new Error('Server error: 500');
                    } else {
                        throw new Error(`Error: ${response.status}`);
                    }
                }
                return response.json();
            })
            .then(data => {setPolicies(data);})
            .catch(error => console.error('Error fetching policies:', error));
    }, []);

    return (
        <div className="container">
            <h1>List of Policies</h1>
            <Table striped bordered hover >
                <thead>
                <tr>
                    <th>Policy ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Schedule</th>
                    <th>Inclusions</th>
                    <th>Exclusions</th>
                    <th>Throttling</th>                    
                    <th>Retention</th>
                    <th>Update Policy</th>
                    <th>Tokens</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {policies.map(policy => (
                    <tr key = {policy.id}>
                        <td><small>{policy.id}</small></td>
                        <td>{policy.name}</td>
                        <td>{policy.description}</td>
                        <td>{scheduleToString(policy.schedule)}</td>
                        <td><ul class="inexcl">{(policy.inclusions || []).map(item => <li key={item}>{item}</li>)}</ul></td>
                        <td><ul class="inexcl">{(policy.exclusions || []).map(item => <li key={item}>{item}</li>)}</ul></td>
                        <td>{throttlingToString(policy.throttling)}</td>
                        <td>{retentionToString(policy.retention)}</td>
                        <td><Button variant = "success" 
                                size = "sm"
                                style = {{
                                    padding: "0.25rem 0.5rem", // Smaller padding for a more compact button
                                    fontSize: "0.8rem" // Smaller font size for a more compact button
                                }}
                                onClick = {() => openUpdatePolicyModal(policy)}>Update Policy</Button></td>
                        <td>
                            <Button
                                variant = "primary"
                                size = "sm"
                                style = {{
                                    padding: "0.25rem 0.5rem", // Smaller padding for a more compact button
                                    fontSize: "0.8rem" // Smaller font size for a more compact button
                                }}
                                onClick = {() => openCreateTokenModal(policy)}
                            >
                                Create Token
                            </Button>
                        </td>
                        <td><Button size = "sm"
                                    style = {{margin: "1rem auto", backgroundColor: "#dee2e6", border: "none"}}
                                    onClick = {() => handleDeletePolicy(policy)}>🗑️</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <AutohideToast show={showToast} setShow={setShowToast} message="Copied to clipboard!" />
            {isUpdatePolicyModalOpen && selectedPolicy && <UpdatePolicy closeModal={closeUpdatePolicyModal} selectedPolicy = {selectedPolicy} tenantId={tenantId}/>}
            {isTokenModalOpen && <CreateToken closeModal={closeCreateTokenModal} policyId={selectedPolicy.id} tenantId={tenantId}/>}
        </div>
    );
};

export default ListPolicies;
