import React from 'react';
import { Col, Row, Toast, ToastContainer } from "react-bootstrap";

export const AutohideToast = ({ show, setShow, message }) => {

  return (
    <Row>
      <Col xs={6}>
      <ToastContainer
          className="p-3"
          position="bottom-center"
          style={{ zIndex: 1 }}
        >
        <Toast bg="success" onClose={() => setShow(false)} show={show} delay={1500} autohide>
          <Toast.Header>
            <strong className="me-auto">Attention</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{ message }</Toast.Body>
        </Toast>
      </ToastContainer>
      </Col>
    </Row>
  );
}

export default AutohideToast;
