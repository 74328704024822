import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { Button, Form, Badge } from "react-bootstrap";
import '../css/FileBrowser.css' // Import CSS for styling
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import Trie, { buildInclusionPaths } from "./Trie";
import { FileInclusionState } from "./Trie";
import { copyToClipboard } from "../Helpers/Clipboard";
import AutohideToast from "../Components/AutohideToast";
/**
 * DirectoryTree = Trie{
 *     TrieNode{
 *         parent : TrieNode{},
 *         data: {path: '', name: '', isDir: false/true},
 *         inclusionState: FileInclusionState.FULL_INCLUDED | FULL_EXCLUDED | PARTIAL_INCLUDED
 *         subDirectory: { 'dir2': Trie{}....},
 *         volume: "C:\"
 * }
 *     }
 * }
 */

const FileBrowser = ({ closeModal, assetId, tenantId }) => {
    const [selectedRecoveryPoint, setSelectedRecoveryPoint] = useState(null);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [recoveryPoints, setRecoveryPoints] = useState([]);
    const [expandedDirectories, setExpandedDirectories] = useState({"/": true});
    const [loadedDirectories, setLoadedDirectories] = useState({"/": true});
    const [directoryTree, setDirectoryTree] = useState(new Trie());
    const [showRestoreModal, setShowRestoreModal] = useState(false);
    const [alternateRestoreId, setAlternateRestoreId] = useState("");
    const [restoreType, setRestoreType] = useState("normal") // Can be normal, alternate, zip
    const [selectedRestoreConflictResolution, setSelectedRestoreConflictResolution] = useState("OVERWRITE_EXISTING") //[OVERWRITE_EXISTING, SKIP_EXISTING, KEEP_NEWEST]
    const [restoreLocation, setRestoreLocation] = useState('C:\\restore')
    const [purgeUnrestored, setPurgeUnrestored] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const [key, setkey] = useState('')

    useEffect(() => {
        if (assetId === "") {
            return;
        }
        fetch(`/api/v1/tenants/${tenantId}/assets/${assetId}/recoveryPoints`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Error fetching recovery points: ${response.status}`);
                }
                return response.json();
            })
            .then(data => { setRecoveryPoints(data); console.log(data); })
            .catch(error => console.error('Error fetching recovery points:', error));
    }, [assetId]);

    const handleRecoveryPointSelect = id => {
        setSelectedRecoveryPoint(id);

        let filteredVolumes = recoveryPoints.filter(backup => backup.backupId === id)[0]["volumes"]
        let clone = Object.assign(Object.create(Object.getPrototypeOf(directoryTree)), directoryTree)
        filteredVolumes.map(element => clone.insert(element.mountpoint,element, {"name": element.mountpoint, "path": "", "isDir": true}))
        setDirectoryTree(clone)
    };

    const handleDirectoryPathAddition = (path, name) => {
        if (name === undefined) {
            name = ""
        }
        if (path[path.length -1] === "\\") {
            return path + name
        } else {
            return path + "/" + name
        }
    }

    const handleDirectorySelect = (event, dir, node) => {
        // I know.... it's ugly :(

        let clone = Object.assign(Object.create(Object.getPrototypeOf(directoryTree)), directoryTree)
        if (node.inclusionState === FileInclusionState.FULL_INCLUDED) {
            clone.updateDirectoryState(dir.path + "\\" + dir.name, false)
        } else {
            clone.updateDirectoryState(dir.path + "\\" + dir.name, true)
        }

        setDirectoryTree(clone)
    };

    const unsecuredCopyToClipboard = (text) => {
        const textArea = document.createElement("textarea")
        textArea.value=text
        document.body.appendChild(textArea)
        textArea.focus();textArea.select()
        try {
            document.execCommand('copy')
        } catch(err) {
            console.error('Unable to copy to clipboard', err)
        }
        document.body.removeChild(textArea)
    };

    const handleDirectoryExpand = (volume, path) => {
        if (loadedDirectories[path]) {
            return;
        }


        let clone = Object.assign(Object.create(Object.getPrototypeOf(directoryTree)), directoryTree)

        let cleanPath = path.replace("\\\\", "\\")
        let mountPointIndex = cleanPath.indexOf(volume.mountpoint) + volume.mountpoint.length-1
        cleanPath = volume.mountpoint + cleanPath.substring(mountPointIndex+1).replaceAll("\\", "/")


        setLoadingFiles(true)
        let browseBody = {
            volumeGuid: volume.guid,
            basePath: cleanPath,
            key: key
        };

        axios.post(`/api/v1/tenants/${tenantId}/assets/${assetId}/recoveryPoints/${selectedRecoveryPoint}/browse`, browseBody)
            .then(response => {
                if (response.status !== 200) {
                    console.log(response)
                    throw new Error(`Error fetching directories: ${response.status}`);
                }

                return response.data;
            })
            .then(data => {
                if (data === undefined || data === null ) {
                    return;
                }
                setLoadedDirectories({...loadedDirectories, [path]: true})
                // We need to remove the repeated path that comes from backend.
                let newData = [];
                // I know.... it's ugly :(
                data.forEach((element) => {
                    if (path
                        !==
                        handleDirectoryPathAddition(element.path,element.name)
                    ) {
                        newData.push(element)
                    }
                    clone.insert(element.path + "\\"+ element.name, volume, element)
                })
                setDirectoryTree(clone)
            })
            .catch(error => console.error('Error fetching directories:', error))
            .finally( () => setLoadingFiles(false));

    }

    const handleAlternateRestoreIdChange = (event) => {
        setAlternateRestoreId(event.target.value);
    };

    const handleRestore = () => {
        // Handle submit logic

        let resultSet = []
        buildInclusionPaths(directoryTree.root,[], resultSet)
        console.log("The resulting inclusions are:", resultSet)
        if (restoreType === "zip") {
            let selectedPaths2 = {
                inclusions: resultSet,
                exclusions: [],
                key: key,
            };
            axios.post(`/api/v1/tenants/${tenantId}/assets/${assetId}/recoveryPoints/${selectedRecoveryPoint}/bundle`, selectedPaths2)
                .then((response) => {
                    const { downloadUrl } = response.data;  // Extract download URL
                    console.log("Bundle ready. Download URL:", downloadUrl);
                    // Copy the download URL to the clipboard
                    copyToClipboard(downloadUrl)
                    setShowToast(true)
                    // Delay closing the modal
                    setTimeout(() => {
                        closeModal();
                    }, 1000);  // Delay for 2 seconds

            }) 
            .catch(error => {
                    console.error('Error initiating restore:', error);
                    alert("Failed to initiate restore");
                });

        } else {
            let selectedPaths2 = {
                inclusions: resultSet,
                exclusions: [],
                key: key,
                flatten: false,
                conflictResolution: "OVERWRITE_EXISTING",
                purgeUnrestored: purgeUnrestored
            };
            if (restoreLocation !== "") {
                selectedPaths2["restoreLocation"] = restoreLocation
            }
            if (restoreType === "alternate") {
                selectedPaths2["restoreAssetId"] = alternateRestoreId
            }
            axios.post(`/api/v1/tenants/${tenantId}/assets/${assetId}/recoveryPoints/${selectedRecoveryPoint}/recover`, selectedPaths2)
                .then(() => {
                    alert("Restore initiated");

                })
                .catch(error => {
                    console.error('Error initiating restore:', error);
                    alert("Failed to initiate restore");
                    setTimeout(() => {
                        closeModal();
                    }, 1000);
                });
        }

        closeModal();
    };

    const DirectoryExpandButton = ({dir, volume}) => {
        let backendPath = handleDirectoryPathAddition(dir.path, dir.name)

        const handleOnClick = () => {
            if (expandedDirectories[backendPath]) {
                setExpandedDirectories({...expandedDirectories, [backendPath] :false})
            } else {
                setExpandedDirectories({...expandedDirectories, [backendPath] :true})
                handleDirectoryExpand(volume, backendPath)
            }
        }

        return (<span
                onClick = {handleOnClick}
                style = {{cursor: 'pointer',fontSize: '13px'}}>{ expandedDirectories[backendPath] ? '🔽': '▶️' }</span>
        )
    }

    const DirectoryTreeNode = ({dir, volume, level}) => {
        const checkboxRef = React.useRef();
        let paddingLeft = level * 40 + "px";
        let currentNode = directoryTree.getDirectoryNode(dir.path + "\\" + dir.name)

        useEffect(() => {
            if (currentNode) {

                if (currentNode.inclusionState === FileInclusionState.FULL_INCLUDED) {
                    checkboxRef.current.checked = true;
                    checkboxRef.current.indeterminate = false;
                } else if (currentNode.inclusionState === FileInclusionState.FULL_EXCLUDED) {
                    checkboxRef.current.checked = false;
                    checkboxRef.current.indeterminate = false;
                } else {
                    checkboxRef.current.checked = false;
                    checkboxRef.current.indeterminate = true;
                }
            }

        }, [currentNode]);

        return (
            <div key = {dir.name} className = "volume-row" style = {{marginLeft: "20px", paddingLeft: paddingLeft}}>
                <div>
                    <div>|</div>
                    <span>----</span>
                    <input
                        ref={checkboxRef}
                        type = "checkbox"
                        onChange = {(event) => handleDirectorySelect(event, dir, currentNode)}
                    />
                    {dir.isDir ? <DirectoryExpandButton dir = {dir} volume = {volume}/> : <></>}

                </div>
                <div>
                    {dir.isDir ? <span>📁</span> : <span>📄</span>}
                    {dir.name}
                </div>
            </div>
        )
    }

    const DirectoryTreeWithTrie = ({directoryTreeNode, volume, level}) => {
        if (!directoryTreeNode) {
            return null;
        }

        let isExpanded = expandedDirectories[handleDirectoryPathAddition(directoryTreeNode.data.path, directoryTreeNode.data.name)];
        return (
            <>
                { directoryTreeNode.volume ? <DirectoryTreeNode dir={directoryTreeNode.data} volume={directoryTreeNode.volume} level={level} key={directoryTreeNode.data.path}/> : <></>}
                {directoryTreeNode.subDirectory && expandedDirectories && isExpanded
                    && Object.values(directoryTreeNode.subDirectory).map(subDirectory =>
                        (
                            <DirectoryTreeWithTrie directoryTreeNode={subDirectory} volume={subDirectory.volume} level={level+1}  key = {subDirectory.data.path + subDirectory.data.name}>

                            </DirectoryTreeWithTrie>
                        )
                    )
                }
            </>
        )
    }
    return (
        <>
            <Modal show={true} onHide={closeModal} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Recovery Points</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="recovery-point-container">
                        <Form.Label>
                            <Badge bg="primary">
                                Input password
                            </Badge>
                            </Form.Label>

                        <Form.Control
                            type="password"
                            id="inputPassword"
                            aria-describedby="passwordHelpBlock"
                            style={{width: "30%"}}
                            onChange={(e) =>setkey(e.target.value) }
                        />
                        <br></br>
                        <table className="recovery-point-table">
                            <thead>
                            <tr>
                                <th>Select</th>
                                <th>Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recoveryPoints !== null && recoveryPoints.length !== 0 && recoveryPoints.map(rp => (
                                <tr key={rp.backupId}>
                                    <td>
                                        <input
                                            type="radio"
                                            name="recoveryPoint"
                                            onChange={() => handleRecoveryPointSelect(rp.backupId)}
                                            checked={selectedRecoveryPoint === rp.backupId}
                                        />
                                    </td>
                                    <td>{rp.startTime}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {selectedRecoveryPoint && (
                            <div className="volume-container">
                                <h2>Volumes</h2>
                                <div>
                                    <div>
                                        {loadingFiles ?   <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner> : <></> }
                                        {
                                            directoryTree !== null && !loadingFiles && directoryTree.root !== null ?
                                                <DirectoryTreeWithTrie directoryTreeNode={directoryTree.root} level={1}></DirectoryTreeWithTrie>: <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button title="Restore" variant="primary" onClick={() => setShowRestoreModal(true)}>
                        Restore
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Footer>
                <AutohideToast show={showToast} setShow={setShowToast} message="Copied to clipboard!" />
            </Modal>
            <Modal show={showRestoreModal} onHide={() => setShowRestoreModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Type of Restore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Check
                        label="Normal restore"
                        name="group1"
                        onChange={() => setRestoreType("normal")}
                        checked={restoreType === "normal"}
                        type="radio">
                    </Form.Check>
                    <Form.Check
                        label="Alternate asset restore"
                        name="group1"
                        onChange={() => setRestoreType("alternate")}
                        checked={restoreType === "alternate"}
                        type="radio">
                    </Form.Check>
                    <Form.Check
                        label="Download as zip"
                        name="group1"
                        onChange={() => setRestoreType("zip")}
                        checked={restoreType === "zip"}
                        type="radio">
                    </Form.Check>
                    { restoreType === "alternate"?
                        <>
                            <br></br>
                            <Form.Label> Target asset to restore:</Form.Label>
                            <Form.Control
                                placeholder="asset shortcode"
                                aria-label="alternate restore assetId"
                                type="text"
                                value={alternateRestoreId}
                                onChange={handleAlternateRestoreIdChange}
                            />
                        </>:<></>
                    }
                    {
                        restoreType !== "zip"?
                            <>
                                <br></br>
                                <Form.Label> Select location to restore </Form.Label>
                                <Form.Control
                                    placeholder="restore location"
                                    aria-label="restore location"
                                    type="text"
                                    value={restoreLocation}
                                    onChange={(e) => setRestoreLocation(e.target.value)}
                                />
                                <br></br>
                                <Form.Label> Select how to handle conflicts during restore </Form.Label>
                                <Form.Select value = {selectedRestoreConflictResolution}
                                             onChange = {
                                                 (event) =>
                                                     setSelectedRestoreConflictResolution(event.target.value)
                                             }
                                >
                                    <option
                                        value = "OVERWRITE_EXISTING"
                                    >
                                        Overwrite existing files
                                    </option>
                                    <option
                                        value = "SKIP_EXISTING"
                                    >
                                        Skip existing files
                                    </option>
                                    <option
                                        value = "KEEP_NEWEST"
                                    >
                                        Keep newer files
                                    </option>
                                </Form.Select>
                                <br></br>
                                <Form.Label> Check if you want to purge unrestored file at restore location </Form.Label>
                                <Form.Check
                                    label="purge unrestored"
                                    name="purge unrestored"
                                    checked={purgeUnrestored}
                                    onChange={(e) => setPurgeUnrestored(e.target.checked)}
                                />
                            </>
                            : <></>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant = "secondary" onClick = {() => setShowRestoreModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRestore}>
                        Restore
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default FileBrowser;
